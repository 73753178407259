.am-navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 45px;
  background-color: #108ee9;
  color: #fff;
}
.am-navbar-left,
.am-navbar-title,
.am-navbar-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.am-navbar-left {
  padding-left: 15px;
  font-size: 16px;
}
.am-navbar-left-icon {
  margin-right: 5px;
  display: inherit;
}
.am-navbar-title {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
  white-space: nowrap;
}
.am-navbar-right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 16px;
  margin-right: 15px;
}
.am-navbar-light {
  background-color: #fff;
  color: #108ee9;
}
.am-navbar-light .am-navbar-title {
  color: #000;
}
